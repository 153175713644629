import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import SignIn from '../login/SignIn';
import SplashImage from '../../assets/stock_photos/auto-xs.png';
// import SplashImage from '../../assets/stock_photos/holiday_background.png';
// import SplashImage from '../../assets/stock_photos/train.png';
// import SplashImage from '../../assets/stock_photos/fisherman.png';

const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  backgroundColor: 'rgba(255, 255, 255, 0.0)',
  // ...theme.typography.body2,
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // toggle for development
  borderRadius: 0,
}));

const ImageItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  ...theme.typography.body1,
  // padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none', // toggle for development
  borderRadius: 0,
}));

const BasicLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('xs')]: {
    height: '80vh',
    minHeight: 350,
    maxHeight: 450,
  },
  [theme.breakpoints.up('sm')]: {
    height: '80vh',
    minHeight: 475,
    maxHeight: 500,
  },
}));

export default function LoginContainer() {
  return (
    <Box>
      <BasicLayoutRoot
        style={{
          backgroundImage: `url(${SplashImage})`,
          backgroundColor: 'rgba(167, 188, 202, 0.3)', // Average color of the background image.
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', // contain or cover
          backgroundPosition: 'center',
        }}
      >
        <Box
          style={{
            height: '100%',
            width: '100%',
            // backgroundColor: 'rgba(93, 134, 160, 0.5)',
            backgroundColor: 'rgba(5, 110, 139, 0.3)',
          }}
        >
          <Container
            sx={{
              // mt: 3,
              // mb: 14,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {/* Increase the network loading priority of the background image. */}
            <img
              style={{ display: 'none' }}
              src={SplashImage}
              alt="increase priority"
            />
            <Grid container spacing={2} sx={{ marginTop: 5 }}>
              <Grid
                item
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                  },
                  width: 250,
                  // marginRight: '3%',
                  marginLeft: '1%',
                }}
              >
                <ImageItem
                  style={{
                    // backgroundImage: `url(${SplashImage})`,
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: 'cover', // contain or cover
                    // backgroundPosition: 'center',
                    height: 375,
                    width: 275,
                    borderRadius: 9,
                  }}
                >
                  <ImageItem
                    style={{
                      height: '100%',
                      width: '100%',
                      backgroundColor: 'rgba(255, 255, 255, 0.0)',
                      padding: '7%',
                      borderRadius: 9,
                      boxShadow:
                        '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                    }}
                  >
                    <SignIn />
                  </ImageItem>
                </ImageItem>
              </Grid>
              <Grid
                item
                md
                sm
                xs={12}
                sx={{
                  marginLeft: {
                    md: '7%',
                  },
                  backgroundColor: 'rgba(255, 255, 255, 0.0)',
                }}
              >
                <Item
                  sx={{
                    fontSize: {
                      xs: 40,
                      sm: 50,
                      md: 50,
                      lg: 60,
                    },
                    fontWeight: 'bold',
                    // color: 'rgb(0, 0, 0, 0.7)',
                    color: 'rgb(240, 240, 240, 1)',
                  }}
                >
                  {/* Hi-Land Credit Card Rewards Await! */}
                  {/* Auto Financing Simplified */}
                  Get Behind the Wheel Today!
                </Item>
                <Item
                  sx={{
                    fontSize: {
                      xs: 20,
                      sm: 25,
                      md: 25,
                      lg: 30,
                    },
                    // color: 'rgb(0, 0, 0, 0.7)',
                    color: 'rgb(240, 240, 240, 1)',
                    padding: 1,
                  }}
                >
                  {/* Unlock rewards with low rates and zero annual fees */}
                  Rates Starting at Just <strong>5.74%</strong> OAC
                </Item>
                <Item>
                  <Box
                    align="left"
                    sx={{
                      paddingLeft: 0,
                    }}
                  >
                    <Button
                      href="/loans#auto"
                      //   href="/contact"
                      variant="contained"
                      sx={{
                        marginTop: 2,
                        borderRadius: 5,
                        backgroundColor: 'rgb(255, 200, 87, 1.0)',

                        // boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: 'rgb(255, 200, 87, 1.0)',
                          // boxShadow:
                          //     '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
                        },
                      }}
                    >
                      Get Started Now
                    </Button>
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </BasicLayoutRoot>
    </Box>
  );
}
