import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function createData(name, minimum_amount, dividend_rate, apy) {
  return { name, minimum_amount, dividend_rate, apy };
}

const rows = [
  createData('6 Months', '$1,000', '2.477%', '2.500%'),
  createData('12 Months', '$1,000', '3.455%', '3.500%'),
  createData('24 Months', '$1,000', '2.477%', '2.500%'),
];

export default function CertificatesTable() {
  return (
    <TableContainer>
      <Table stickyHeader={true} aria-label="table">
        <TableHead>
          <TableRow>
            <TableCell>Term</TableCell>
            <TableCell align="right">Minimum Amount</TableCell>
            <TableCell align="right">Dividend Rate</TableCell>
            <TableCell align="right">APY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                },
              }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.minimum_amount}</TableCell>
              <TableCell align="right">{row.dividend_rate}</TableCell>
              <TableCell align="right">{row.apy}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
