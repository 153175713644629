import * as React from 'react';

import MainAppBar from '../components/nav/MainAppBar';
import LoginContainer from '../components/containers/LoginContainer';
import CardContainer from '../components/containers/CardContainer';
// import PromoContainer from '../components/containers/PromoContainer';
import PromoContainer from '../components/containers/PromoContainer2';
import AppFooter from '../components/nav/AppFooter';

import Container from '@mui/material/Container';

function HomePage() {
  return (
    <Container disableGutters>
      <MainAppBar />
      <LoginContainer />
      <CardContainer />
      {/* <PromoContainer /> */}
      <PromoContainer />
      <AppFooter />
      {/* <AnnouncementsContainer /> */}
      {/* <Box marginX={'10%'}>
                <SecondaryAppBar />
            </Box> */}

      {/* <Home /> */}
      {/* <MainAppDrawer>
                <Container sx={{ bgcolor: 'background.container' }}>
                    <SecretsHeader />
                    <SecretsGrid />
                </Container>
            </MainAppDrawer> */}
    </Container>
  );
}

export default HomePage;
